<script>
import result from 'lodash/result';
import invoke from 'lodash/invoke';

import { mapActions, mapGetters, mapState } from 'vuex';
import { DATE_FORMAT } from '@emobg/web-utils';

// Components
import InsurancePSD2Modal from '@/vue/components/organism/Insurances/InsurancePSD2Modal/InsurancePSD2Modal';
import PaymentSuccessModal from '@/components/PaymentMethods/PaymentSuccessModal';

import SuccessModal from '@/vue/components/organism/Insurances/InsuranceCancelAutorenew/SuccessModal';
// Mixins
import AuthenticatedUserMixin from '@/mixins/AuthenticatedUser';
import ModalMixin from '@/mixins/Modal';
import SegmentMixin from '@/mixins/Segment';
import EventHandlerMixin from '@/mixins/EventHandler';
// Constants
import { SEGMENT_EVENTS } from '@/vue/constants';
import { isAnnualInsurance, isBasicInsurance, isFullInsurance } from '@/helpers/booking/insurancesHelpers';

import { nameSpace as UserDataNameSpace } from '@/vue/stores/UserData/UserDataStore';
import { nameSpace as UserInsuranceNameSpace } from '@/vue/stores/UserInsurance/UserInsuranceStore';
import { nameSpace as InsurancesModuleNameSpace } from '@/stores/Insurances/InsurancesModule';

import { protection } from '@/utils/publicImages';
import { errorNotification } from '@/handlers/errorHandler.const';

import { GTM_MODAL_EVENTS } from '@/constants/gtm';
import { useTrackingGTM } from '@/composable/GTM/gtm';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';

import { genericErrorArgs, genericSuccessArgs } from '@/constants/defaultModalArgs';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import { storage } from '@Shared/Payments/PaymentMethodsHub/composables/storage';
import { PAYMENT_REDIRECTED, RESPONSE_STATUS } from '@Shared/Payments/PaymentMethodsHub/constants/paymentMethodsHub';
import { useTheme } from '@/composable/Theme/useTheme';

const Marked = require('marked');

export default {

  components: {
    InsurancePSD2Modal,
    PaymentSuccessModal,
    FeedbackModalComponent,
  },

  mixins: [
    AuthenticatedUserMixin,
    ModalMixin,
    SegmentMixin,
    EventHandlerMixin,
  ],

  inject: ['paymentSuccess'],

  props: {
    insurance: {
      type: Object,
      default: () => ({}),
    },
  },

  setup() {
    const { trackPageView, trackModalView } = useTrackingGTM();
    const { notifyError } = useNotifications();
    const { fetchButtonSpecs } = useTheme();
    return { notifyError, trackPageView, trackModalView, fetchButtonSpecs };
  },

  data() {
    return {
      insurancePSD2: {},
      isPSD2ModalVisible: false,
      isPSD2SuccessModalVisible: false,

      modals: {
        active: {
          isOpen: false,
        },
      },
    };
  },

  computed: {
    ...mapGetters(UserDataNameSpace, { userData: 'getUserData' }),
    ...mapState(UserInsuranceNameSpace, {
      userInsurance: state => state.currentInsurance,
    }),

    ...mapState(InsurancesModuleNameSpace, {
      cancelledInsurance: state => state.cancelRenewal.data,
      isCancelled: state => state.cancelRenewal.STATUS.LOADED,
      cancelError: state => state.cancelRenewal.error,
    }),

    isUserInsurance() {
      return this.userInsurance.uuid === this.insurance.uuid;
    },

    isAnnualInsuranceContractedByUser() {
      return this.isUserInsurance && this.isAnnualInsurance(this.insurance);
    },
  },

  watch: {
    cancelError(newError) {
      if (newError) {
        this.notifyError(errorNotification(this.error));
        this.trackPageView();
        this.closeModalComponent();
      }
    },

    isCancelled(isLoaded) {
      if (isLoaded) {
        this.setUserInsurance(this.cancelledInsurance);
        this.trackPageView();
        this.setModalComponent(SuccessModal);
      }
    },

    paymentSuccess(value) {
      if (value.response.matchingName === this.insurance.name) {
        if (value.isValid) {
          this.closePSD2Modal();
          this.showSuccessFeedback();
        }

        // only show refused feedback upon redirect or challenge/identify flows, as the rest will be shown on the payment method modal itself
        const additionalStepResponses = [RESPONSE_STATUS.challenge, RESPONSE_STATUS.identify];

        if (
          !value.isValid && (storage.get(PAYMENT_REDIRECTED)
          || additionalStepResponses.includes(value.response.loopOrigin)
          )) {
          this.showRefusedFeedback(value.response);

          storage.remove(PAYMENT_REDIRECTED);
        }
      }
    },
  },

  created() {
    this.protection = protection;

    this.DATE_FORMAT = DATE_FORMAT;

    this.successModalArgs = {
      ...genericSuccessArgs(this.$t),
      image: protection,
      title: this.$t('modal.insurance.success.title'),
      primaryCallToAction: () => {
        this.updateInsurance();

        this.modals.active.isOpen = false;
      },
    };
  },

  methods: {
    invoke,
    result,
    isAnnualInsurance,
    isBasicInsurance,
    isFullInsurance,

    ...mapActions(InsurancesModuleNameSpace, ['deleteAutorenewInsurance']),

    ...mapActions(UserInsuranceNameSpace, ['setUserInsurance']),

    showBuyInsuranceModal(insurance) {
      this.trackSegment({
        name: SEGMENT_EVENTS.ANNUAL_INSURANCE,
      });

      this.insurancePSD2 = insurance;
      this.isPSD2ModalVisible = true;

      this.trackModalView({ modalName: GTM_MODAL_EVENTS.selectPaidInsurancesConfirmationModal });
    },

    compiledMarkdown(markDown) {
      const markDownText = markDown || '';
      return Marked(markDownText, { sanitize: true, breaks: true });
    },

    cancelRenewInsurance() {
      this.deleteAutorenewInsurance({
        userUuid: this.userData.uuid,
        insuranceUuid: this.userInsurance.uuid,
      });
    },

    async closePSD2Modal() {
      this.isPSD2ModalVisible = false;
    },

    async updateInsurance() {
      const { insurance } = await this.fetchAuthenticatedUser();
      this.setUserInsurance(insurance);
    },

    showSuccessFeedback() {
      this.modals.active = {
        ...this.successModalArgs,
        isOpen: true,
      };
    },

    showRefusedFeedback(response) {
      this.modals.active = {
        ...genericErrorArgs(this.$t),
        title: response.providerMessage || this.$t('modal.confirm_payment.error.title'),
        description: '',
        isOpen: true,
        primaryCallToAction: () => {
          this.modals.active.isOpen = false;
        },
      };
    },
  },
};
</script>
<template>
  <div
    :class="[
      'phInsuranceCard',
      {
        'phInsuranceCard--active': isAnnualInsuranceContractedByUser
      }
    ]"
    data-test-id="insurance-card"
  >
    <div class="phInsuranceCard__wrapper py-6 px-5">
      <div class="phInsuranceCard__wrapper__body">
        <div
          v-if="isAnnualInsuranceContractedByUser"
          class="position-absolute mt-n5 w-80 align-right"
        >
          <ui-icon
            class="emobg-background-color-success p-1 circle mr-n3"
            :color="GRAYSCALE.white"
            :icon="ICONS.check"
            :size="ICONS_SIZES.small"
          />
        </div>
        <h2
          class="phInsuranceCard__wrapper__body__title mb-4"
          data-test-id="insurance-name"
        >
          {{ insurance.name }}
        </h2>
        <p
          v-if="isAnnualInsurance(insurance)"
          class="phInsuranceCard__wrapper__body__price emobg-title-m mb-5"
        >
          <span data-test-id="insurance-price">
            {{ insurance.price }} / {{ $t('modal.tariff.quotas.year').toLowerCase() }}
          </span>
        </p>
        <div
          class="phInsuranceCard__wrapper__body__paragraph emobg-body-m-regular"
          data-test-id="insurance-description"
          v-html="compiledMarkdown(insurance.description)"
        />
      </div>

      <div class="mt-7 mb-3 d-flex justify-content-center align-items-center flex-column emobg-body-m-regular">
        <template v-if="isBasicInsurance(insurance)">
          <div>
            <ui-icon
              :icon="ICONS.check"
              :color="COLORS.success"
              :size="ICONS_SIZES.small"
            />
            <span class="emobg-color-ink-light">
              {{ $t('personal_profile.insurance.by_default') }}
            </span>
          </div>
        </template>

        <template v-if="isFullInsurance(insurance)">
          <div>
            <span class="emobg-color-ink-light">
              {{ $t('personal_profile.insurance.add_reservation') }}
            </span>
          </div>
        </template>

        <template v-if="!isUserInsurance && isAnnualInsurance(insurance)">
          <ui-button
            v-bind="fetchButtonSpecs()"
            class="w-60"
            data-test-id="buy-insurance-button"
            @clickbutton="showBuyInsuranceModal(insurance)"
          >
            {{ $t('personal_profile.insurance.buy') }}
          </ui-button>
        </template>

        <template v-if="isAnnualInsuranceContractedByUser">
          <span class="phInsuranceCard__renew">
            <ui-badge
              :color="GRAYSCALE.ground"
              class="mt-2"
              data-test-id="expiration-renewal-badge"
            >
              {{ userInsurance.auto_renew
                ? $t('personal_profile.insurance.renew')
                : $t('personal_profile.insurance.expire')
              }}
              {{ invoke(result(userInsurance, 'getExpiresAt'), 'format', DATE_FORMAT.dob) }}
            </ui-badge>
          </span>

          <ui-button
            v-if="userInsurance.auto_renew"
            v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.DESTRUCTIVE })"
            class="mt-3 emobg-font-weight-bold"
            data-test-id="cancel-reneval-button"
            @clickbutton="cancelRenewInsurance"
          >
            {{ $t('personal_profile.action.cancel_renovation') }}
          </ui-button>
        </template>
      </div>
    </div>

    <InsurancePSD2Modal
      v-if="isPSD2ModalVisible"
      :insurance="insurancePSD2"
      data-test-id="psd2-modal"
      @close-modal="closePSD2Modal"
    />
    <PaymentSuccessModal
      v-if="isPSD2SuccessModalVisible"
      :title="$t('modal.insurance.success.modal_title')"
      :background="protection"
      data-test-id="psd2-success-modal"
      @on:close="() => isPSD2SuccessModalVisible = false"
    />

    <FeedbackModalComponent
      v-model="modals.active.isOpen"
      v-bind="modals.active"
    />
  </div>
</template>
